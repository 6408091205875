import {configureStore} from '@reduxjs/toolkit';
import { createManufacturerSlice,  createProductSlice, createRetailerSlice, createSupplierSlice,  getAllManufacturerSlice, getAllProductSlice, getAllSupplierSlice, getAllRetailerSlice,  getOneManufacturerSlice,  getOneProductSlice, getOneRetailerSlice, getOneSupplierSlice } from './Slices/marketMapTableSlices.js';
import { FilterBoxToggleToggleSlice, SideBarToggleSlice } from './Slices/sideBarSlice.js';
import { deleteManufacturerSlice,  deleteProductSlice, deleteRetailerSlice, deleteSupplierSlice, updateManufacturerSlice,  updateProductSlice, updateRetailerSlice, updateSupplierSlice } from './Slices/marketMapTableEditSlice.js';

import { createGroupAttributeGroupSlice, createManufacturerAttributeGroupSlice, createPackagingAttributeGroupSlice, createProductAttributeGroupSlice, createProgramAttributeGroupSlice, createPromotionAttributeGroupSlice, createRegionAttributeGroupSlice, createRetailerAttributeGroupSlice, createSupplierAttributeGroupSlice, getAllGroupAttributesSlice, getAllManufacturerAttributesSlice, getAllPackagingAttributesSlice, getAllProductAttributesSlice, getAllProgramAttributesSlice, getAllPromotionAttributesSlice, getAllRegionAttributesSlice, getAllRetailerAttributesSlice, getAllSupplierAttributesSlice, getOneGroupAttributesSlice, getOneManufacturerAttributesSlice, getOnePackagingAttributesSlice, getOneProductAttributesSlice, getOneProgramAttributesSlice, getOnePromotionAttributesSlice, getOneRegionAttributesSlice, getOneRetailerAttributesSlice, getOneSupplierAttributesSlice, updateGroupAttributeGroupSlice, updateGroupGroupNameSlice, updateManufacturerAttributeGroupSlice, updateManufacturerGroupNameSlice, updatePackagingAttributeGroupSlice, updatePackagingGroupNameSlice, updateProductAttributeGroupSlice, updateProductGroupNameSlice, updateProgramAttributeGroupSlice, updateProgramGroupNameSlice, updatePromotionAttributeGroupSlice, updatePromotionGroupNameSlice, updateRegionAttributeGroupSlice, updateRegionGroupNameSlice, updateRetailerAttributeGroupSlice, updateRetailerGroupNameSlice, updateSupplierAttributeGroupSlice, updateSupplierGroupNameSlice } from './Slices/marketMapAttributeSlice.js';

import {DataSelectionNamesSlice, selectedAttributesSlice, selectedFilterTableAttributesSlice, selectedFilterTableQuerysSlice, selectedQuerysSlice} from './Slices/marketMapShortedDataSlice.js';

import { addGroupInProgramSlice, addManufacturerInProgramSlice, addPackagingInProgramSlice, addProductInProgramSlice, addPromotionInProgramSlice, addRegionInProgramSlice, addRetailerInProgramSlice, addSupplierInProgramSlice, createProgramSlice, deleteManufacturerInProgramSlice, deleteProductInProgramSlice, deleteProgramSlice, deleteRetailerInProgramSlice, deleteSupplierInProgramSlice, getAllFilteredRetailersByProgramSlice, getAllProgramSlice, getOneProgramSlice, updateProgramSlice } from './Slices/marketMapProgram.js';
import { authSlice, extractUserSlice, getAllUsersSlice, updateUserRoleSlice, userLoginSlice, userLogoutSlice, userPasswordSlice, userRegisterSlice, userTokenSlice, userValidateSlice } from './Slices/userAuthSlice.js';
import { deleteProgramImgSlice, getManufacturerImgSlice, getProductImgSlice, getProgramImgSlice, getRetailerImgSlice, getSupplierImgSlice, updateManufacturerImgSlice,  updateProductImgSlice, updateProgramImgSlice,  updateRetailerImgSlice, updateSupplierImgSlice,  uploadManufacturerImgSlice,  uploadProductImgSlice, uploadProgramImgSlice,   uploadRetailerImgSlice, uploadSupplierImgSlice } from './Slices/marketMapImageSlice.js';
import { getAllFilteredManufacturerSlice, getAllFilteredProductSlice, getAllFilteredProgramSlice, getAllFilteredRetailerSlice, getAllFilteredSupplierSlice } from './Slices/marketMapGetAllFilter.js';
import { getAllOperationSetupSlice, getAllStageSetupSlice, StageSetupSlice } from './TraceabilitySlice/stageSetup.js';



const store = configureStore({
    reducer : {

        StageSetup: StageSetupSlice.reducer, 
        AllStageSetup: getAllStageSetupSlice.reducer, 
        AllOperationSetup: getAllOperationSetupSlice.reducer, 



        selectedQuerys: selectedQuerysSlice.reducer, 
        AllselectedAttributesHeading: selectedAttributesSlice.reducer,

        selectedFilterTableQuerys: selectedFilterTableQuerysSlice.reducer, 
        AllselectedFilterTableAttributesHeading: selectedFilterTableAttributesSlice.reducer,

        DataSelectionNames: DataSelectionNamesSlice.reducer,
        UserRegister : userRegisterSlice.reducer,
        UserToken : userTokenSlice.reducer,
        UserValidate : userValidateSlice.reducer,
        UserLogin : userLoginSlice.reducer,
        UserLogout : userLogoutSlice.reducer,
        UserPassword : userPasswordSlice.reducer,
        auth : authSlice.reducer,
        AllUsers : getAllUsersSlice.reducer,
        ExtractUser : extractUserSlice.reducer,
        UpdateUserRole : updateUserRoleSlice.reducer,
        
        sideBarToggle: SideBarToggleSlice.reducer,
        filterBoxToggle: FilterBoxToggleToggleSlice.reducer,

        GetAllFilteredProduct: getAllFilteredProductSlice.reducer,
        GetAllFilteredRetailer: getAllFilteredRetailerSlice.reducer,
        GetAllFilteredManufacturer: getAllFilteredManufacturerSlice.reducer,
        GetAllFilteredSupplier: getAllFilteredSupplierSlice.reducer,
        GetAllFilteredProgram: getAllFilteredProgramSlice.reducer,
        GetAllFilteredRetailersByProgram: getAllFilteredRetailersByProgramSlice.reducer,



        Product: createProductSlice.reducer,
        AllProducts : getAllProductSlice.reducer,
        OneProduct : getOneProductSlice.reducer,
        
        Retailer : createRetailerSlice.reducer,
        AllRetailers : getAllRetailerSlice.reducer,
        OneRetailer : getOneRetailerSlice.reducer,

        Manufacturer : createManufacturerSlice.reducer,
        AllManufacturers : getAllManufacturerSlice.reducer,
        OneManufacturer : getOneManufacturerSlice.reducer,
        
        Supplier : createSupplierSlice.reducer,
        AllSuppliers : getAllSupplierSlice.reducer,
        OneSupplier : getOneSupplierSlice.reducer,
        

        DeleteProduct : deleteProductSlice.reducer,
        DeleteRetailer : deleteRetailerSlice.reducer,
        DeleteManufacturer : deleteManufacturerSlice.reducer,
        DeleteSupplier : deleteSupplierSlice.reducer,

        UpdateProduct : updateProductSlice.reducer,
        UpdateRetailer : updateRetailerSlice.reducer,
        UpdateManufacturer : updateManufacturerSlice.reducer,
        UpdateSupplier : updateSupplierSlice.reducer,



        ProductAttributeGroup : createProductAttributeGroupSlice.reducer,
        UpdateProductAttributeGroup : updateProductAttributeGroupSlice.reducer,
        AllProductAttributeGroup : getAllProductAttributesSlice.reducer,
        OneProductAttributesGroup : getOneProductAttributesSlice.reducer,
        UpdateProductGroupName : updateProductGroupNameSlice.reducer,

        RetailerAttributeGroup : createRetailerAttributeGroupSlice.reducer,
        UpdateRetailerAttributeGroup : updateRetailerAttributeGroupSlice.reducer,
        AllRetailerAttributeGroup : getAllRetailerAttributesSlice.reducer,
        OneRetailerAttributesGroup : getOneRetailerAttributesSlice.reducer,
        UpdateRetailerGroupName : updateRetailerGroupNameSlice.reducer,


        ManufacturerAttributeGroup : createManufacturerAttributeGroupSlice.reducer,
        UpdateManufacturerAttributeGroup : updateManufacturerAttributeGroupSlice.reducer,
        AllManufacturerAttributeGroup : getAllManufacturerAttributesSlice.reducer,
        OneManufacturerAttributesGroup : getOneManufacturerAttributesSlice.reducer,
        UpdateManufacturerGroupName : updateManufacturerGroupNameSlice.reducer,


        SupplierAttributeGroup : createSupplierAttributeGroupSlice.reducer,
        UpdateSupplierAttributeGroup : updateSupplierAttributeGroupSlice.reducer,
        AllSupplierAttributeGroup : getAllSupplierAttributesSlice.reducer,
        OneSupplierAttributesGroup : getOneSupplierAttributesSlice.reducer,
        UpdateSupplierGroupName : updateSupplierGroupNameSlice.reducer,
        

        PackagingAttributeGroup : createPackagingAttributeGroupSlice.reducer,
        UpdatePackagingAttributeGroup : updatePackagingAttributeGroupSlice.reducer,
        AllPackagingAttributeGroup : getAllPackagingAttributesSlice.reducer,
        OnePackagingAttributesGroup : getOnePackagingAttributesSlice.reducer,
        UpdatePackagingGroupName : updatePackagingGroupNameSlice.reducer,


        PromotionAttributeGroup : createPromotionAttributeGroupSlice.reducer,
        UpdatePromotionAttributeGroup : updatePromotionAttributeGroupSlice.reducer,
        AllPromotionAttributeGroup : getAllPromotionAttributesSlice.reducer,
        OnePromotionAttributesGroup : getOnePromotionAttributesSlice.reducer,
        UpdatePromotionGroupName : updatePromotionGroupNameSlice.reducer,


        ProgramAttributeGroup : createProgramAttributeGroupSlice.reducer,
        UpdateProgramAttributeGroup : updateProgramAttributeGroupSlice.reducer,
        AllProgramAttributeGroup : getAllProgramAttributesSlice.reducer,
        OneProgramAttributesGroup : getOneProgramAttributesSlice.reducer,
        UpdateProgramGroupName : updateProgramGroupNameSlice.reducer,


        RegionAttributeGroup : createRegionAttributeGroupSlice.reducer,
        UpdateRegionAttributeGroup : updateRegionAttributeGroupSlice.reducer,
        AllRegionAttributeGroup : getAllRegionAttributesSlice.reducer,
        OneRegionAttributesGroup : getOneRegionAttributesSlice.reducer,
        UpdateRegionGroupName : updateRegionGroupNameSlice.reducer,


        GroupAttributeGroup : createGroupAttributeGroupSlice.reducer,
        UpdateGroupAttributeGroup : updateGroupAttributeGroupSlice.reducer,
        AllGroupAttributeGroup : getAllGroupAttributesSlice.reducer,
        OneGroupAttributesGroup : getOneGroupAttributesSlice.reducer,
        UpdateGroupGroupName : updateGroupGroupNameSlice.reducer,



        Program : createProgramSlice.reducer,
        AllPrograms : getAllProgramSlice.reducer,
        UpdateProgram  : updateProgramSlice.reducer,
        OneProgram  : getOneProgramSlice.reducer,
        DeleteProgram  : deleteProgramSlice.reducer,

        RetailerInProgram : addRetailerInProgramSlice.reducer,
        ManufacturerInProgram : addManufacturerInProgramSlice.reducer,
        SupplierInProgram : addSupplierInProgramSlice.reducer,
        ProductInProgram : addProductInProgramSlice.reducer,
        PackagingInProgram : addPackagingInProgramSlice.reducer,
        PromotionInProgram : addPromotionInProgramSlice.reducer,
        RegionInProgram : addRegionInProgramSlice.reducer,
        GroupInProgram : addGroupInProgramSlice.reducer,


        DeleteRetailerInProgram : deleteRetailerInProgramSlice.reducer,
        DeleteManufacturerInProgram : deleteManufacturerInProgramSlice.reducer,
        DeleteSupplierInProgram : deleteSupplierInProgramSlice.reducer,
        DeleteProductInProgram : deleteProductInProgramSlice.reducer,



        
        UploadProductImg : uploadProductImgSlice.reducer,
        GetProductImg : getProductImgSlice.reducer,
        UpdateProductImg : updateProductImgSlice.reducer,

        UploadRetailerImg : uploadRetailerImgSlice.reducer,
        GetRetailerImg : getRetailerImgSlice.reducer,
        UpdateRetailerImg : updateRetailerImgSlice.reducer,

        UploadManufacturerImg : uploadManufacturerImgSlice.reducer,
        GetManufacturerImg : getManufacturerImgSlice.reducer,
        UpdateManufacturerImg : updateManufacturerImgSlice.reducer,

        UploadSupplierImg : uploadSupplierImgSlice.reducer,
        GetSupplierImg : getSupplierImgSlice.reducer,
        UpdateSupplierImg : updateSupplierImgSlice.reducer,

        UploadProgramImg : uploadProgramImgSlice.reducer,
        GetProgramImg : getProgramImgSlice.reducer,
        UpdateProgramImg : updateProgramImgSlice.reducer,
        DeleteProgramImg : deleteProgramImgSlice.reducer,



    }
});



export default store;