import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from 'axios';
import STATUSES from '../Statuses';
import config from '../../config';
import createApiSlice from "./Common/CommoCreateSlice";

const serviceURL0 = config.SERVICE_0_URL;
const serviceURL1 = config.SERVICE_1_URL;
const serviceURL2 = config.SERVICE_2_URL;
const serviceURL3 = config.SERVICE_3_URL;
const serviceURL4 = config.SERVICE_4_URL;



// ======================================= Product ========================================

 
// const uploadProductImgSlice = createSlice({
//     name : "uploadProductImg",
//     initialState : {
//         data :{},
//         status : STATUSES.IDEAL,
//         error : null
//     },
//     extraReducers : (builder)=>{
//         builder
//         .addCase(uploadProductImg.pending, (state , action)=>{
//             state.status = STATUSES.LOADING
//         })

//         .addCase(uploadProductImg.fulfilled, (state , action)=>{
//             state.data = action.payload
//             state.status = STATUSES.SUCCESS
//             state.error = null
//         })

//         .addCase(uploadProductImg.rejected, (state , action)=>{
//             state.error = action.payload
//             state.status = STATUSES.ERROR
//         })
//     }
// });

// upload Product Img == post API
export const uploadProductImg = createAsyncThunk( "Product/UploadImg", async ( data, { rejectWithValue ,getState })=>{
    try {
        
        const state = getState();
        const token = state.auth.token; 
        const config = { 
            headers: { "Authorization": `Bearer ${token}`, "Content-Type": "multipart/form-data", },
            params: {productId :data.productId  ,imageName :data.imageName , productClassName : data.className}
        };

        const responce = await axios.post(`${serviceURL1}/product/uploadOrUpdateImagesForProduct`,data.formData, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})

const uploadProductImgSlice = createApiSlice('uploadProductImg', uploadProductImg , true );



const getProductImgSlice = createSlice({
    name: 'productImage',
    initialState: {
        images: {},
        status: STATUSES.IDLE,
        error: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getProductImg.pending, (state) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getProductImg.fulfilled, (state, action) => {
                const { productId, imageName, imageUrl } = action.payload;
                state.status = STATUSES.SUCCESS;
                state.images[`${productId}_${imageName}`] = imageUrl;
            })
            .addCase(getProductImg.rejected, (state, action) => {
                state.status = STATUSES.ERROR;
                state.error = action.payload;
            });
    }
});
export const getProductImg = createAsyncThunk("product/getImg",  async (data, { rejectWithValue ,getState }) => {
    try {
            
        const state = getState();
        const token = state.auth.token; 
        const config = { 
            headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" },
            params: { productId: data.productId, imageName: data.imageName }
        };

        const response = await axios.get(`${serviceURL1}/product/imageUrlsForProduct`, config);
            return { imageUrl: response.data[0], productId: data.productId, imageName: data.imageName };

        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);




const updateProductImgSlice = createSlice({
    name : "updateProductImg",
    initialState : {
        data :{},
        status : STATUSES.IDEAL,
        error : null
    },
    extraReducers : (builder)=>{
        builder
        .addCase(updateProductImg.pending, (state , action)=>{
            state.status = STATUSES.LOADING
        })

        .addCase(updateProductImg.fulfilled, (state , action)=>{
            state.data = action.payload
            state.status = STATUSES.SUCCESS
            state.error = null
        })

        .addCase(updateProductImg.rejected, (state , action)=>{
            state.error = action.payload
            state.status = STATUSES.ERROR
        })
    }
});
// update Product Img == post API
export const updateProductImg = createAsyncThunk( "Product/updateImg", async ( data, { rejectWithValue ,getState })=>{
    try {
        
        const state = getState();
        const token = state.auth.token; 
        const config = { 
            headers: { "Authorization": `Bearer ${token}`, "Content-Type": "multipart/form-data", },
            params: {productId :data.productId  ,imageName :data.imageName }
        };

        const responce = await axios.post(`${serviceURL1}/product/updateImagesForProduct`,data.formData, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})







// ======================================= Retailer ========================================

// const uploadRetailerImgSlice = createSlice({
//     name : "uploadRetailerImg",
//     initialState : {
//         data :{},
//         status : STATUSES.IDEAL,
//         error : null
//     },
//     extraReducers : (builder)=>{
//         builder
//         .addCase(uploadRetailerImg.pending, (state , action)=>{
//             state.status = STATUSES.LOADING
//         })

//         .addCase(uploadRetailerImg.fulfilled, (state , action)=>{
//             state.data = action.payload
//             state.status = STATUSES.SUCCESS
//             state.error = null
//         })

//         .addCase(uploadRetailerImg.rejected, (state , action)=>{
//             state.error = action.payload
//             state.status = STATUSES.ERROR
//         })
//     }
// });

// upload Retailer Img == post API
export const uploadRetailerImg = createAsyncThunk( "Retailer/UploadImg", async ( data, { rejectWithValue ,getState })=>{
    try {
        
        const state = getState();
        const token = state.auth.token; 
        const config = { 
            headers: { "Authorization": `Bearer ${token}`, "Content-Type": "multipart/form-data", },
            params: {retailerId :data.retailerId  ,imageName :data.imageName , retailerClassName :data.className }
        };

        const responce = await axios.post(`${serviceURL2}/retailers/uploadOrUpdateImagesForRetailer`,data.formData, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})

const uploadRetailerImgSlice = createApiSlice('uploadRetailerImg', uploadRetailerImg , true );

 

const getRetailerImgSlice = createSlice({
    name: 'retailerImage',
    initialState: {
        images: {},
        status: STATUSES.IDLE,
        error: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getRetailerImg.pending, (state) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getRetailerImg.fulfilled, (state, action) => {
                const { retailerId, imageName, imageUrl } = action.payload;
                state.status = STATUSES.SUCCESS;
                state.images[`${retailerId}_${imageName}`] = imageUrl;
            })
            .addCase(getRetailerImg.rejected, (state, action) => {
                state.status = STATUSES.ERROR;
                state.error = action.payload;
            });
    }
});
export const getRetailerImg = createAsyncThunk("retailer/getImg", async (data, { rejectWithValue ,getState }) => {
    try {
            
        const state = getState();
        const token = state.auth.token; 
        const config = { 
            headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" },
            params: { retailerId: data.retailerId, imageName: data.imageName }
        };

        const response = await axios.get(`${serviceURL1}/retailers/imageUrlsForRetailer`, config);
            return { imageUrl: response.data[0], retailerId: data.retailerId, imageName: data.imageName };
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

const updateRetailerImgSlice = createSlice({
    name : "updateRetailerImg",
    initialState : {
        data :{},
        status : STATUSES.IDEAL,
        error : null
    },
    extraReducers : (builder)=>{
        builder
        .addCase(updateRetailerImg.pending, (state , action)=>{
            state.status = STATUSES.LOADING
        })

        .addCase(updateRetailerImg.fulfilled, (state , action)=>{
            state.data = action.payload
            state.status = STATUSES.SUCCESS
            state.error = null
        })

        .addCase(updateRetailerImg.rejected, (state , action)=>{
            state.error = action.payload
            state.status = STATUSES.ERROR
        })
    }
});
// update Retailer Img == post API
export const updateRetailerImg = createAsyncThunk( "Retailer/updateImg", async ( data, { rejectWithValue ,getState })=>{
    try {
        
        const state = getState();
        const token = state.auth.token; 
        const config = { 
            headers: { "Authorization": `Bearer ${token}`, "Content-Type": "multipart/form-data", },
            params: {retailerId :data.retailerId  ,imageName :data.imageName }
        };

        const responce = await axios.post(`${serviceURL2}/retailers/updateImagesForRetailer`,data.formData,config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})





// ======================================= Manufacturer ========================================

// const uploadManufacturerImgSlice = createSlice({
//     name : "uploadManufacturerImg",
//     initialState : {
//         data :{},
//         status : STATUSES.IDEAL,
//         error : null
//     },
//     extraReducers : (builder)=>{
//         builder
//         .addCase(uploadManufacturerImg.pending, (state , action)=>{
//             state.status = STATUSES.LOADING
//         })

//         .addCase(uploadManufacturerImg.fulfilled, (state , action)=>{
//             state.data = action.payload
//             state.status = STATUSES.SUCCESS
//             state.error = null
//         })

//         .addCase(uploadManufacturerImg.rejected, (state , action)=>{
//             state.error = action.payload
//             state.status = STATUSES.ERROR
//         })
//     }
// });

// upload Manufacturer Img == post API
export const uploadManufacturerImg = createAsyncThunk( "Manufacturer/UploadImg", async ( data, { rejectWithValue ,getState })=>{
    try {
        
        const state = getState();
        const token = state.auth.token; 
        const config = { 
            headers: { "Authorization": `Bearer ${token}`, "Content-Type": "multipart/form-data", },
            params: {manufacturerId :data.manufacturerId  ,imageName :data.imageName , manufacturerClassName  : data.className}
            };

        const responce = await axios.post(`${serviceURL3}/manufacturers/uploadOrUpdateImagesForManufacturer`,data.formData,config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})

const uploadManufacturerImgSlice = createApiSlice('uploadManufacturerImg', uploadManufacturerImg , true );


const getManufacturerImgSlice = createSlice({
    name: 'manufacturerImage',
    initialState: {
        images: {},
        status: STATUSES.IDLE,
        error: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getManufacturerImg.pending, (state) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getManufacturerImg.fulfilled, (state, action) => {
                const { manufacturerId, imageName, imageUrl } = action.payload;
                state.status = STATUSES.SUCCESS;
                state.images[`${manufacturerId}_${imageName}`] = imageUrl;
            })
            .addCase(getManufacturerImg.rejected, (state, action) => {
                state.status = STATUSES.ERROR;
                state.error = action.payload;
            });
    }
});
export const getManufacturerImg = createAsyncThunk("manufacturer/getImg",async (data, { rejectWithValue ,getState }) => {
    try {
            
        const state = getState();
        const token = state.auth.token; 
        const config = { 
            headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" },
            params: { manufacturerId: data.manufacturerId, imageName: data.imageName }
        };

        const response = await axios.get(`${serviceURL1}/manufacturers/imageUrlsForManufacturer`, config);
            return { imageUrl: response.data[0], manufacturerId: data.manufacturerId, imageName: data.imageName };
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);



const updateManufacturerImgSlice = createSlice({
    name : "updateManufacturerImg",
    initialState : {
        data :{},
        status : STATUSES.IDEAL,
        error : null
    },
    extraReducers : (builder)=>{
        builder
        .addCase(updateManufacturerImg.pending, (state , action)=>{
            state.status = STATUSES.LOADING
        })

        .addCase(updateManufacturerImg.fulfilled, (state , action)=>{
            state.data = action.payload
            state.status = STATUSES.SUCCESS
            state.error = null
        })

        .addCase(updateManufacturerImg.rejected, (state , action)=>{
            state.error = action.payload
            state.status = STATUSES.ERROR
        })
    }
});
// update Manufacturer Img == post API
export const updateManufacturerImg = createAsyncThunk( "Manufacturer/updateImg", async ( data, { rejectWithValue ,getState })=>{
    try {
        
        const state = getState();
        const token = state.auth.token; 
        const config = { 
            headers: { "Authorization": `Bearer ${token}`, "Content-Type": "multipart/form-data", },
            params: {manufacturerId :data.manufacturerId  ,imageName :data.imageName }
        };

        const responce = await axios.post(`${serviceURL3}/manufacturers/updateImagesForManufacturer`,data.formData, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})





// ======================================= Supplier ========================================

// const uploadSupplierImgSlice = createSlice({
//     name : "uploadSupplierImg",
//     initialState : {
//         data :{},
//         status : STATUSES.IDEAL,
//         error : null
//     },
//     extraReducers : (builder)=>{
//         builder
//         .addCase(uploadSupplierImg.pending, (state , action)=>{
//             state.status = STATUSES.LOADING
//         })

//         .addCase(uploadSupplierImg.fulfilled, (state , action)=>{
//             state.data = action.payload
//             state.status = STATUSES.SUCCESS
//             state.error = null
//         })

//         .addCase(uploadSupplierImg.rejected, (state , action)=>{
//             state.error = action.payload
//             state.status = STATUSES.ERROR
//         })
//     }
// });

// upload Supplier Img == post API
export const uploadSupplierImg = createAsyncThunk( "Supplier/UploadImg", async ( data, { rejectWithValue ,getState })=>{
    try {
        
        const state = getState();
        const token = state.auth.token; 
        const config = { 
            headers: { "Authorization": `Bearer ${token}`, "Content-Type": "multipart/form-data", },
            params: {supplierId :data.supplierId  ,imageName :data.imageName , supplierClassName  : data.className}
        };

        const responce = await axios.post(`${serviceURL4}/suppliers/uploadOrUpdateImagesForSupplier`,data.formData, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})

const uploadSupplierImgSlice = createApiSlice('uploadSupplierImg', uploadSupplierImg , true );



const getSupplierImgSlice = createSlice({
    name: 'supplierImage',
    initialState: {
        images: {},
        status: STATUSES.IDLE,
        error: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getSupplierImg.pending, (state) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getSupplierImg.fulfilled, (state, action) => {
                const { supplierId, imageName, imageUrl } = action.payload;
                state.status = STATUSES.SUCCESS;
                state.images[`${supplierId}_${imageName}`] = imageUrl;
            })
            .addCase(getSupplierImg.rejected, (state, action) => {
                state.status = STATUSES.ERROR;
                state.error = action.payload;
            });
    }
});

export const getSupplierImg = createAsyncThunk("supplier/getImg",async (data, { rejectWithValue ,getState }) => {
    try {
            
        const state = getState();
        const token = state.auth.token; 
        const config = { 
            headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" },
            params: { supplierId: data.supplierId, imageName: data.imageName }
        };

        const response = await axios.get(`${serviceURL1}/suppliers/imageUrlsForSupplier`, config);
            return { imageUrl: response.data[0], supplierId: data.supplierId, imageName: data.imageName };
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);



const updateSupplierImgSlice = createSlice({
    name : "updateSupplierImg",
    initialState : {
        data :{},
        status : STATUSES.IDEAL,
        error : null
    },
    extraReducers : (builder)=>{
        builder
        .addCase(updateSupplierImg.pending, (state , action)=>{
            state.status = STATUSES.LOADING
        })

        .addCase(updateSupplierImg.fulfilled, (state , action)=>{
            state.data = action.payload
            state.status = STATUSES.SUCCESS
            state.error = null
        })

        .addCase(updateSupplierImg.rejected, (state , action)=>{
            state.error = action.payload
            state.status = STATUSES.ERROR
        })
    }
});
// update Supplier Img == post API
export const updateSupplierImg = createAsyncThunk( "Supplier/updateImg", async ( data, { rejectWithValue ,getState })=>{
    try {
        
        const state = getState();
        const token = state.auth.token; 
        const config = { 
            headers: { "Authorization": `Bearer ${token}`, "Content-Type": "multipart/form-data", },
            params: {supplierId :data.supplierId  ,imageName :data.imageName }
        };

        const responce = await axios.post(`${serviceURL4}/suppliers/updateImagesForSupplier`,data.formData, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})











// ======================================= Program ========================================


 
// const uploadProgramImgSlice = createSlice({
//     name : "uploadProgramImg",
//     initialState : {
//         data :{},
//         status : STATUSES.IDEAL,
//         error : null
//     },
//     extraReducers : (builder)=>{
//         builder
//         .addCase(uploadProgramImg.pending, (state , action)=>{
//             state.status = STATUSES.LOADING
//         })

//         .addCase(uploadProgramImg.fulfilled, (state , action)=>{
//             state.data = action.payload
//             state.status = STATUSES.SUCCESS
//             state.error = null
//         })

//         .addCase(uploadProgramImg.rejected, (state , action)=>{
//             state.error = action.payload
//             state.status = STATUSES.ERROR
//         })
//     }
// });



export const uploadProgramImg = createAsyncThunk("Program/UploadImg", async (data, { rejectWithValue, getState }) => {
      try {
        const state = getState();
        const token = state.auth.token;
        const config = {
          headers: { "Authorization": `Bearer ${token}`, "Content-Type": "multipart/form-data", },
          params: { programId: data.programId, imageName: data.imageName , programClassName  : data.className},
        };
  
        const response = await axios.post(`${serviceURL0}/programs/uploadOrUpdateImagesForProgram`, data.formData, config);
        return response.data;
      } catch (error) {
        return rejectWithValue(error.response ? error.response.data : error.message);
      }
    }
);


const uploadProgramImgSlice = createApiSlice('uploadProgramImg', uploadProgramImg , true );




const getProgramImgSlice = createSlice({
    name: 'programImage',
    initialState: {
        images: {},
        status: STATUSES.IDLE,
        error: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getProgramImg.pending, (state) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getProgramImg.fulfilled, (state, action) => {
                const { programId, imageName, imageUrl } = action.payload;
                state.status = STATUSES.SUCCESS;
                state.images[`${programId}_${imageName}`] = imageUrl;
            })
            .addCase(getProgramImg.rejected, (state, action) => {
                state.status = STATUSES.ERROR;
                state.error = action.payload;
            });
    }
});

export const getProgramImg = createAsyncThunk("program/getImg",async (data, { rejectWithValue ,getState }) => {
    try {
            
        const state = getState();
        const token = state.auth.token; 
        const config = { 
            headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" },
            params: { programId: data.programId, imageName: data.imageName , className : data.className }
        };

        const response = await axios.get(`${serviceURL0}/programs/imageUrlsForProgram`, config);
            return { imageUrl: response.data[0], programId: data.programId, imageName: data.imageName };
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);




const updateProgramImgSlice = createSlice({
    name : "updateProgramImg",
    initialState : {
        data :{},
        status : STATUSES.IDEAL,
        error : null
    },
    extraReducers : (builder)=>{
        builder
        .addCase(updateProgramImg.pending, (state , action)=>{
            state.status = STATUSES.LOADING
        })

        .addCase(updateProgramImg.fulfilled, (state , action)=>{
            state.data = action.payload
            state.status = STATUSES.SUCCESS
            state.error = null
        })

        .addCase(updateProgramImg.rejected, (state , action)=>{
            state.error = action.payload
            state.status = STATUSES.ERROR
        })
    }
});
// update Program Img == post API
export const updateProgramImg = createAsyncThunk( "Program/updateImg", async ( data, { rejectWithValue ,getState })=>{
    try {
        
        const state = getState();
        const token = state.auth.token; 
        const config = { 
            headers: { "Authorization": `Bearer ${token}`, "Content-Type": "multipart/form-data", },
            params: {programId :data.programId  ,imageName :data.imageName }
        };

        const responce = await axios.post(`${serviceURL0}/programs/updateImagesForProgram`,data.formData, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})


const deleteProgramImgSlice = createSlice({
    name: 'deletePprogramImage',
    initialState: {
        data: {},
        status: STATUSES.IDLE,
        error: null
    },
    reducers : {
        resetdeleteProgramImgState :((state , action)=>{
            state.error = null
            state.status = STATUSES.IDEAL
            state.data = {}
        })
    },
    extraReducers : (builder)=>{
        builder
        .addCase(deleteProgramImg.pending, (state , action)=>{
            state.status = STATUSES.LOADING
        })

        .addCase(deleteProgramImg.fulfilled, (state , action)=>{
            state.data = action.payload
            state.status = STATUSES.SUCCESS
            state.error = null
        })

        .addCase(deleteProgramImg.rejected, (state , action)=>{
            state.error = action.payload
            state.status = STATUSES.ERROR
        })
    }
});

export const deleteProgramImg = createAsyncThunk("program/deleteImg",async (data, { rejectWithValue ,getState }) => {
    try {
            
        const state = getState();
        const token = state.auth.token; 
        const config = { 
            headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" },
            params: { programId: data.programId, imageName: data.imageName , programClassName  : data.className}
        };

        const responce = await axios.delete(`${serviceURL0}/programs/deleteImagesForProgram`, config);
        return responce.data

        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);







export {
    uploadProductImgSlice,
    getProductImgSlice,
    updateProductImgSlice,

    uploadRetailerImgSlice,
    getRetailerImgSlice,
    updateRetailerImgSlice,

    uploadManufacturerImgSlice,
    getManufacturerImgSlice,
    updateManufacturerImgSlice,

    uploadSupplierImgSlice,
    getSupplierImgSlice,
    updateSupplierImgSlice,


    uploadProgramImgSlice,
    getProgramImgSlice,
    updateProgramImgSlice,
    deleteProgramImgSlice
}

export const {resetdeleteProgramImgState} = deleteProgramImgSlice.actions;

